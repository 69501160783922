// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-play-areas-js": () => import("./../src/pages/play-areas.js" /* webpackChunkName: "component---src-pages-play-areas-js" */),
  "component---src-pages-snow-effects-js": () => import("./../src/pages/snow-effects.js" /* webpackChunkName: "component---src-pages-snow-effects-js" */),
  "component---src-pages-snow-slides-js": () => import("./../src/pages/snow-slides.js" /* webpackChunkName: "component---src-pages-snow-slides-js" */),
  "component---src-pages-snow-tubing-js": () => import("./../src/pages/snow-tubing.js" /* webpackChunkName: "component---src-pages-snow-tubing-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

